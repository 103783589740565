import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";

import Home from "../src/Pages/Home";
import About from "../src/Pages/About";
import Shop from "../src/Pages/Shop";
import Contact from "../src/Pages/Contact";
import Blog from "../src/Pages/Blog";
import Header from "../src/Components/Header/Navbar";
import Footer from "../src/Components/Footer/Footer";
import ProductDetails from "./Pages/ProductDetails";
import NotFound from "./Pages/NotFound";
import ScrollToTop from "./Components/ScrollButton/ScrollToTop";
import Authentication from "./Pages/Authentication";
import ResetPass from "./Components/Authentication/Reset/ResetPass";
import BlogDetails from "./Components/Blog/BlogDetails/BlogDetails";
import TermsConditions from "./Pages/TermsConditions";
import ShoppingCart from "./Components/ShoppingCart/ShoppingCart";
// import Popup from "./Components/PopupBanner/Popup";
import { Toaster } from "react-hot-toast";
import Orders from "./Components/Dashboard/Orders/Orders";
import AccountDetails from "./Components/Dashboard/AccountDetails/AccountDetails";
import Addresses from "./Components/Dashboard/Addresses/Addresses";
import Account from "./Pages/Account";

const App = () => {
	const [isLoggedIn, setIsLoggedIn] = React.useState(false);

	return (
		<>
			{/* <Popup /> */}
			<ScrollToTop />
			<BrowserRouter>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/shop" element={<Shop />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/product/:product_id" element={<ProductDetails />} />
					<Route path="/resetPassword" element={<ResetPass />} />
					<Route path="/blog-details" element={<BlogDetails />} />
					<Route path="/terms" element={<TermsConditions />} />
					<Route path="/cart" element={<ShoppingCart />} />
					<Route path="*" element={<NotFound />} />

					{isLoggedIn ? (
						<Route path="/account" element={<Account />}>
							<Route index element={<Navigate to="details" />} />
							<Route path="details" element={<AccountDetails />} />
							<Route path="orders" element={<Orders />} />
							<Route path="addresses" element={<Addresses />} />
						</Route>
					) : (
						<Route path="/account" element={<Authentication />} />
					)}
				</Routes>
				<Footer />
				<Toaster position="bottom-center" />
			</BrowserRouter>
		</>
	);
};

export default App;
