import React from "react";
import "./Orders.css";

function Orders() {
	const orders = [
		{
			id: "#2416",
			date: "October 1, 2023",
			status: "On hold",
			total: "$1,200.65",
			items: 3,
		},
		{
			id: "#2417",
			date: "October 2, 2023",
			status: "On hold",
			total: "$1,200.65",
			items: 3,
		},
		{
			id: "#2418",
			date: "October 3, 2023",
			status: "On hold",
			total: "$1,200.65",
			items: 3,
		},
		{
			id: "#2419",
			date: "October 4, 2023",
			status: "On hold",
			total: "$1,200.65",
			items: 3,
		},
	];

	return (
		<div className="orders-page">
			<h1 className="page-title">Orders</h1>
			<table className="orders-table">
				<thead>
					<tr>
						<th>ORDER</th>
						<th>DATE</th>
						<th>STATUS</th>
						<th>TOTAL</th>
						<th>ACTIONS</th>
					</tr>
				</thead>
				<tbody>
					{orders.map((order, index) => (
						<tr key={index}>
							<td>
								<a href="#">{order.id}</a>
							</td>
							<td>{order.date}</td>
							<td>{order.status}</td>
							<td>
								{order.total} for {order.items} items
							</td>
							<td>
								<button className="view-button">VIEW</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default Orders;
