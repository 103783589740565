import React from "react";
import BlogList from "../Components/Blog/BlogList/BlogList";

const Blog = () => {
  return (
    <>
      <BlogList />
    </>
  );
};

export default Blog;
