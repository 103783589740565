import React from "react";
import AboutPage from "../Components/About/AboutPage";

const About = () => {
  return (
    <>
      <AboutPage />
    </>
  );
};

export default About;
