import React, { useState } from "react";
import "./LoginSignUp.css";
import { Link } from "react-router-dom";
import axios from "../../../Lib/Axios";
import toast from "react-hot-toast";

const LoginSignUp = () => {
	const [activeTab, setActiveTab] = useState("login");

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [phone, setPhone] = useState("");

	const handleTab = (tab) => {
		setActiveTab(tab);
	};

	const handleRegister = (e) => {
		e.preventDefault();
		axios
			.post("/users/", {
				name: name,
				email: email,
				password: password,
				phone: phone,
				role: "customer",
			})
			.then((res) => {
				toast.success("Registered Successfully, Please Login!");
				setActiveTab("login");
			})
			.catch((err) => {
				toast.error(err.response?.data?.error);
				console.log("🚀 ~ handleRegister ~ err:", err);
			});
	};

	const handleLogin = (e) => {
		e.preventDefault();
		axios
			.post("/users/login/", {
				email: email,
				password: password,
			})
			.then((res) => {
				toast.success(res.data?.message);
			})
			.catch((err) => {
				toast.error(err.response?.data?.error);
				console.log("🚀 ~ handleLogin ~ err:", err.response);
			});
	};

	return (
		<>
			<div className="loginSignUpSection">
				<div className="loginSignUpContainer">
					<div className="loginSignUpTabs">
						<p
							onClick={() => handleTab("login")}
							className={activeTab === "login" ? "active" : ""}
						>
							Login
						</p>
						<p
							onClick={() => handleTab("register")}
							className={activeTab === "register" ? "active" : ""}
						>
							Register
						</p>
					</div>
					<div className="loginSignUpTabsContent">
						{/* LOGIN TAB */}
						{activeTab === "login" && (
							<div className="loginSignUpTabsContentLogin">
								<form>
									<input
										type="email"
										placeholder="Email address *"
										required
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									<input
										type="password"
										placeholder="Password *"
										required
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<div className="loginSignUpForgetPass">
										<label>
											<input type="checkbox" className="brandRadio" />
											<p>Remember me</p>
										</label>
										<p>
											<Link to="/resetPassword">Lost password?</Link>
										</p>
									</div>
									<button onClick={handleLogin}>Log In</button>
								</form>
								<div className="loginSignUpTabsContentLoginText">
									<p>
										No account yet?{" "}
										<span onClick={() => handleTab("register")}>
											Create Account
										</span>
									</p>
								</div>
							</div>
						)}

						{/* REGISTER TAB */}
						{activeTab === "register" && (
							<div className="loginSignUpTabsContentRegister">
								<form>
									<input
										type="text"
										placeholder="Name *"
										required
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
									<input
										type="email"
										placeholder="Email address *"
										required
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									<input
										type="password"
										placeholder="Password *"
										required
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<input
										type="number"
										placeholder="Phone *"
										required
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
									/>
									<button onClick={handleRegister}>Register</button>
								</form>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginSignUp;
