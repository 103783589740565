import React from "react";
import ShopDetails from "../Components/Shop/ShopDetails/ShopDetails";

const Shop = () => {
  return (
    <>
      <ShopDetails />
    </>
  );
};

export default Shop;
