import React from "react";
import Error from "../Components/Error/Error";

const NotFound = () => {
  return (
    <>
      <Error />
    </>
  );
};

export default NotFound;
