import { useQuery } from "@tanstack/react-query";
import axios from "../Lib/Axios";

const useProductDetails = (productId) =>
	useQuery({
		queryKey: ["product", productId],
		queryFn: () => axios.get(`/products/${productId}/`).then((res) => res.data),
	});

export default useProductDetails;
