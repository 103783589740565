import React from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";

function Sidebar() {
	return (
		<div className="sidebar">
			<h2>DASHBOARD</h2>
			<ul>
				<li>
					<Link to="orders">Orders</Link>
				</li>
				<li>
					<Link to="addresses">Addresses</Link>
				</li>
				<li>
					<Link to="details">Account Details</Link>
				</li>
				<li>
					<Link to="wishlist">Wishlist</Link>
				</li>
				<li>
					<Link to="logout">Logout</Link>
				</li>
			</ul>
		</div>
	);
}

export default Sidebar;
