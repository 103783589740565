import blog1 from "../Assets/Blog/blog1.jpg";
import blog2 from "../Assets/Blog/blog2.jpg";
import blog3 from "../Assets/Blog/blog3.jpg";
import blog4 from "../Assets/Blog/blog4.jpg";
import blog5 from "../Assets/Blog/blog5.jpg";
import blog6 from "../Assets/Blog/blog6.jpg";

let BlogData = [
  {
    blogID: 1,
    blogThumbnail: blog1,
    blogDate: "April 01, 2024",
    blogHeading: "Woman with good shoes is never be ugly place",
  },
  {
    blogID: 2,
    blogThumbnail: blog2,
    blogDate: "April 14, 2024",
    blogHeading: "Heaven upon heaven moveth every have known",
  },
  {
    blogID: 3,
    blogThumbnail: blog3,
    blogDate: "April 24, 2024",
    blogHeading: "Tree doesn't good void, waters without created",
  },
  {
    blogID: 4,
    blogThumbnail: blog4,
    blogDate: "April 30, 2024",
    blogHeading: "Given Set was without from god divide rule Hath",
  },
  {
    blogID: 5,
    blogThumbnail: blog5,
    blogDate: "May 02, 2024",
    blogHeading: "Tree earth fowl given moveth deep lesser after sky",
  },
  {
    blogID: 6,
    blogThumbnail: blog6,
    blogDate: "May 19, 2024",
    blogHeading: "Us yielding Fish sea night night the said him two",
  },
];

export default BlogData;
