import React, { useState } from "react";
import "./ShopDetails.css";

import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../Features/Cart/cartSlice";

import Filter from "../Filters/Filter";
import { Link } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import { IoFilterSharp, IoClose } from "react-icons/io5";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { FaCartPlus } from "react-icons/fa";
import toast from "react-hot-toast";
import useProducts from "../../../Hooks/useProducts";
import Skeleton from "react-loading-skeleton";

const ShopDetails = () => {
	const { data, isLoading } = useProducts();
	const dispatch = useDispatch();

	const [wishList, setWishList] = useState({});
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const handleWishlistClick = (product_id) => {
		setWishList((prevWishlist) => ({
			...prevWishlist,
			[product_id]: !prevWishlist[product_id],
		}));
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	const closeDrawer = () => {
		setIsDrawerOpen(false);
	};

	const cartItems = useSelector((state) => state.cart.items);

	const handleAddToCart = (product) => {
		const productInCart = cartItems.find(
			(item) => item.product_id === product.product_id
		);

		if (productInCart && productInCart.quantity >= 20) {
			toast.error("Product limit reached", {
				duration: 2000,
				style: {
					backgroundColor: "#ff4b4b",
					color: "white",
				},
				iconTheme: {
					primary: "#fff",
					secondary: "#ff4b4b",
				},
			});
		} else {
			dispatch(addToCart(product));
			toast.success("Added to cart!");
		}
	};

	return (
		<>
			<div className="shopDetails">
				<div className="shopDetailMain">
					<div className="shopDetails__left">
						<Filter />
					</div>
					<div className="shopDetails__right">
						<div className="shopDetailsSorting">
							<div className="shopDetailsBreadcrumbLink">
								<Link to="/" onClick={scrollToTop}>
									Home
								</Link>
								&nbsp;/&nbsp;
								<Link to="/shop">Shop</Link>
							</div>
							<div className="filterLeft" onClick={toggleDrawer}>
								<IoFilterSharp />
								<p>Filter</p>
							</div>
							<div className="shopDetailsSort">
								<select name="sort" id="sort">
									<option value="default">Default Sorting</option>
									<option value="Featured">Featured</option>
									<option value="bestSelling">Best Selling</option>
									<option value="a-z">Alphabetically, A-Z</option>
									<option value="z-a">Alphabetically, Z-A</option>
									<option value="lowToHigh">Price, Low to high</option>
									<option value="highToLow">Price, high to low</option>
									<option value="oldToNew">Date, old to new</option>
									<option value="newToOld">Date, new to old</option>
								</select>
								<div className="filterRight" onClick={toggleDrawer}>
									<div className="filterSeprator"></div>
									<IoFilterSharp />
									<p>Filter</p>
								</div>
							</div>
						</div>
						<div className="shopDetailsProducts">
							<div className="shopDetailsProductsContainer">
								{data?.results?.map((product, index) => (
									<div key={index} className="sdProductContainer">
										<div className="sdProductImages">
											<Link
												to={`/product/${product?.product_id}`}
												onClick={scrollToTop}
											>
												<img
													src={product?.images[0]?.image_url}
													alt=""
													className="sdProduct_front"
												/>
												<img
													src={product?.images[1]?.image_url}
													alt=""
													className="sdProduct_back"
												/>
											</Link>
											<h4 onClick={() => handleAddToCart(product)}>
												Add to Cart
											</h4>
										</div>
										<div
											className="sdProductImagesCart"
											onClick={() => handleAddToCart(product)}
										>
											<FaCartPlus />
										</div>
										<div className="sdProductInfo">
											<div className="sdProductCategoryWishlist">
												<p>{product?.category?.name?.toUpperCase()}</p>
												<FiHeart
													onClick={() =>
														handleWishlistClick(product?.product_id)
													}
													style={{
														color: wishList[product?.product_id]
															? "red"
															: "#767676",
														cursor: "pointer",
													}}
												/>
											</div>
											<div className="sdProductNameInfo">
												<Link to="/product" onClick={scrollToTop}>
													<h5>{product?.name}</h5>
												</Link>

												<div className="flex flex-row">
													<p className="line-through text-neutral-500">
														₹ {product?.price}
													</p>
													<p className="ml-2">₹ {product?.discount_price}</p>
												</div>
												<div className="sdProductRatingReviews">
													<div className="sdProductRatingStar">
														<FaStar color="#FEC78A" size={10} />
														<FaStar color="#FEC78A" size={10} />
														<FaStar color="#FEC78A" size={10} />
														<FaStar color="#FEC78A" size={10} />
														<FaStar color="#FEC78A" size={10} />
													</div>
													{/* <span>{product.productReviews}</span> */}
												</div>
											</div>
										</div>
									</div>
								))}
								{isLoading &&
									Array(6)
										.fill()
										.map((_, index) => (
											<div key={index} className="sdProductContainer">
												<div className="sdProductImages">
													<Skeleton height={300} />
												</div>
												<div className="sdProductInfo">
													<div className="sdProductCategoryWishlist">
														<Skeleton width={100} height={20} />
													</div>
													<div className="sdProductNameInfo">
														<Skeleton width={200} height={20} />
														<div className="flex flex-row">
															<Skeleton height={20} />
														</div>
														<div className="sdProductRatingReviews">
															<div className="sdProductRatingStar">
																<Skeleton width={50} height={20} />
															</div>
															<Skeleton width={50} height={20} />
														</div>
													</div>
												</div>
											</div>
										))}
							</div>
						</div>
						<div className="shopDetailsPagination">
							<div className="sdPaginationPrev">
								<p onClick={scrollToTop}>
									<FaAngleLeft />
									Prev
								</p>
							</div>
							<div className="sdPaginationNumber">
								<div className="paginationNum">
									<p onClick={scrollToTop}>1</p>
									<p onClick={scrollToTop}>2</p>
									<p onClick={scrollToTop}>3</p>
									<p onClick={scrollToTop}>4</p>
								</div>
							</div>
							<div className="sdPaginationNext">
								<p onClick={scrollToTop}>
									Next
									<FaAngleRight />
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Drawer */}
			<div className={`filterDrawer ${isDrawerOpen ? "open" : ""}`}>
				<div className="drawerHeader">
					<p>Filter By</p>
					<IoClose onClick={closeDrawer} className="closeButton" size={26} />
				</div>
				<div className="drawerContent">
					<Filter />
				</div>
			</div>
		</>
	);
};

export default ShopDetails;
