import React from "react";
import "./AccountDetails.css";

function AccountDetails() {
	return (
		<div className="account-details-page">
			<h1 className="page-title">Account Details</h1>
			<form className="account-form">
				<div className="form-group">
					<label htmlFor="name">Full Name:</label>
					<input type="text" id="name" defaultValue="John Doe" />
				</div>

				<div className="form-group">
					<label htmlFor="email">Email Address:</label>
					<input type="email" id="email" defaultValue="john.doe@example.com" />
				</div>

				<div className="form-group">
					<label htmlFor="phone">Phone Number:</label>
					<input type="text" id="phone" defaultValue="123-456-7890" />
				</div>

				{/* <div className="form-group">
					<label htmlFor="password">Password:</label>
					<input type="password" id="password" defaultValue="password123" />
				</div> */}

				<button className="action-button" type="submit">
					Save Changes
				</button>
			</form>
		</div>
	);
}

export default AccountDetails;
