import { useQuery } from "@tanstack/react-query";
import axios from "../Lib/Axios";

const useProducts = () =>
	useQuery({
		queryKey: ["products"],
		queryFn: () => axios.get("/products/").then((res) => res.data),
	});

export default useProducts;
