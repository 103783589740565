import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	items: [],
	totalAmount: 0,
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addToCart(state, action) {
			const product = action.payload;
			const existingItem = state.items.find(
				(item) => item.product_id === product.product_id
			);
			if (existingItem) {
				if (existingItem.quantity < existingItem.quantity_in_stock) {
					existingItem.quantity += 1;
					state.totalAmount += parseFloat(product.discount_price);
				}
			} else {
				state.items.push({ ...product, quantity: 1 });
				state.totalAmount += parseFloat(product.discount_price);
			}
		},
		updateQuantity(state, action) {
			const { product_id, quantity } = action.payload;
			const itemToUpdate = state.items.find(
				(item) => item.product_id === product_id
			);
			if (itemToUpdate) {
				const difference = quantity - itemToUpdate.quantity;
				if (quantity <= itemToUpdate.quantity_in_stock) {
					itemToUpdate.quantity = quantity;
					state.totalAmount +=
						difference * parseFloat(itemToUpdate.discount_price);
				} else {
					itemToUpdate.quantity = itemToUpdate.quantity_in_stock;
					state.totalAmount +=
						(itemToUpdate.quantity_in_stock - itemToUpdate.quantity) *
						parseFloat(itemToUpdate.discount_price);
				}
			}
		},
		removeFromCart(state, action) {
			const product_id = action.payload;
			const itemToRemove = state.items.find(
				(item) => item.product_id === product_id
			);
			if (itemToRemove) {
				state.totalAmount -=
					parseFloat(itemToRemove.discount_price) * itemToRemove.quantity;
				state.items = state.items.filter(
					(item) => item.product_id !== product_id
				);
			}
		},
	},
});

export const { addToCart, removeFromCart, updateQuantity } = cartSlice.actions;

export const selectCartItems = (state) => state.cart.items;
export const selectCartTotalAmount = (state) => state.cart.totalAmount;

export default cartSlice.reducer;
