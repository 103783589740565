import React, { useState } from "react";
import "./Filter.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoIosArrowDown } from "react-icons/io";
import Slider from "@mui/material/Slider";
import useCategories from "../../../Hooks/useCategories";
import Skeleton from "react-loading-skeleton";

const Filter = () => {
	const { data, isLoading } = useCategories();
	const [value, setValue] = useState([20, 69]);

	const [selectedColors, setSelectedColors] = useState([]);
	const [selectedSizes, setSelectedSizes] = useState([]);

	const handleColorChange = (color) => {
		setSelectedColors((prevColors) =>
			prevColors.includes(color)
				? prevColors.filter((c) => c !== color)
				: [...prevColors, color]
		);
	};

	const handleSizeChange = (size) => {
		setSelectedSizes((prevSizes) =>
			prevSizes.includes(size)
				? prevSizes.filter((s) => s !== size)
				: [...prevSizes, size]
		);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const filterColors = [
		"#0B2472",
		"#D6BB4F",
		"#282828",
		"#B0D6E8",
		"#9C7539",
		"#D29B47",
		"#E5AE95",
		"#D76B67",
		"#BABABA",
		"#BFDCC4",
	];

	const filterSizes = ["XS", "S", "M", "L", "XL", "XXL"];

	return (
		<div>
			<div className="filterSection">
				<div className="filterCategories">
					<Accordion defaultExpanded disableGutters elevation={0}>
						<AccordionSummary
							expandIcon={<IoIosArrowDown size={20} />}
							aria-controls="panel1-content"
							id="panel1-header"
							sx={{ padding: 0, marginBottom: 2 }}
						>
							<h5 className="filterHeading">Product Categories</h5>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: 0 }}>
							{data?.results?.map((category, index) => (
								<p key={index}>{category?.name}</p>
							))}
							{isLoading &&
								Array(6)
									.fill()
									.map((_, index) => (
										<div key={index}>
											<Skeleton height={20} width={100} />
										</div>
									))}
						</AccordionDetails>
					</Accordion>
				</div>
				<div className="filterColors">
					<Accordion defaultExpanded disableGutters elevation={0}>
						<AccordionSummary
							expandIcon={<IoIosArrowDown size={20} />}
							aria-controls="panel1-content"
							id="panel1-header"
							sx={{ padding: 0, marginBottom: 2 }}
						>
							<h5 className="filterHeading">Color</h5>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: 0 }}>
							{
								<div className="filterColorBtn">
									{filterColors.map((color, index) => (
										<button
											key={index}
											className={`colorButton ${
												selectedColors.includes(color) ? "selected" : ""
											}`}
											style={{
												backgroundColor: color,
											}}
											onClick={() => handleColorChange(color)}
										/>
									))}
								</div>
							}
						</AccordionDetails>
					</Accordion>
				</div>
				<div className="filterSizes">
					<Accordion defaultExpanded disableGutters elevation={0}>
						<AccordionSummary
							expandIcon={<IoIosArrowDown size={20} />}
							aria-controls="panel1-content"
							id="panel1-header"
							sx={{ padding: 0, marginBottom: 2 }}
						>
							<h5 className="filterHeading">Sizes</h5>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: 0 }}>
							<div className="sizeButtons">
								{filterSizes.map((size, index) => (
									<button
										key={index}
										className={`sizeButton ${
											selectedSizes.includes(size) ? "selected" : ""
										}`}
										onClick={() => handleSizeChange(size)}
									>
										{size}
									</button>
								))}
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className="filterPrice">
					<Accordion defaultExpanded disableGutters elevation={0}>
						<AccordionSummary
							expandIcon={<IoIosArrowDown size={20} />}
							aria-controls="panel1-content"
							id="panel1-header"
							sx={{ padding: 0, marginBottom: 2 }}
						>
							<h5 className="filterHeading">Price</h5>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: 0 }}>
							<Slider
								getAriaLabel={() => "Temperature range"}
								value={value}
								onChange={handleChange}
								valueLabelDisplay="auto"
								valueLabelFormat={(value) => `$${value}`}
								sx={{
									color: "black",
									"& .MuiSlider-thumb": {
										backgroundColor: "white",
										border: "2px solid black",
										width: 18,
										height: 18,
									},
								}}
							/>

							<div className="filterSliderPrice">
								<div className="priceRange">
									<p>
										Min Price: <span>${value[0]}</span>
									</p>
									<p>
										Max Price: <span>${value[1]}</span>
									</p>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			</div>
		</div>
	);
};

export default Filter;
