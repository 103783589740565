import React from "react";
import "./Addresses.css";

function Addresses() {
	const addresses = [
		{
			id: 1,
			name: "John Doe",
			address: "1234 Main St, Springfield, IL, 62704",
			phone: "123-456-7890",
		},
		{
			id: 2,
			name: "Jane Doe",
			address: "5678 Elm St, Springfield, IL, 62705",
			phone: "987-654-3210",
		},
	];

	return (
		<div className="addresses-page">
			<h1 className="page-title">Addresses</h1>
			<div className="address-list">
				{addresses.map((address) => (
					<div key={address.id} className="address-card">
						<div className="address-info">
							<h3>{address.name}</h3>
							<p>{address.address}</p>
							<p>{address.phone}</p>
						</div>
						<div className="address-actions">
							<button className="action-button">Edit</button>
							<button className="action-button delete-button">Delete</button>
						</div>
					</div>
				))}
			</div>
			<button className="action-button add-address">Add New Address</button>
		</div>
	);
}

export default Addresses;
