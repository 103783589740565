import React from "react";
import LoginSignUp from "../Components/Authentication/LoginSign/LoginSignUp";

const Authentication = () => {
	return (
		<>
			<LoginSignUp />
		</>
	);
};

export default Authentication;
