import Axios from "axios";

const axios = Axios.create({
	baseURL: "http://13.232.254.170:8000/api",
});

const authRequestInterceptor = (config) => {
	// const token = useUserStore.getState().token;
	config.headers = config.headers || {};
	// // if (token) {
	// // 	config.headers.authorization = `Bearer ${token}`;
	// // }
	// config.headers.Accept = "application/json";
	// config.headers["Content-Type"] = "application/json";
	// config.headers["Access-Control-Allow-Origin"] = "*";
	return config;
};

const requestErrorHandler = (err) => {
	console.log("🚀 ~ req.interceptor ~", err);
	return Promise.reject(err);
};

const responseSuccessHandler = (response) => {
	return response;
};

const responseErrorHandler = (err) => {
	if (err.isAxiosError && !err.response) {
	}
	console.log("🚀 ~ res.interceptor ~", err.response?.data);
	return Promise.reject(err);
};

axios.interceptors.request.use(authRequestInterceptor, requestErrorHandler);
axios.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

export default axios;
