import React from "react";

import "./HeroSection.css";
import { Link } from "react-router-dom";

const HeroSection = () => {
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<>
			<div className="heroMain !py-20">
				<div className="sectionleft">
					<p>New Trend</p>
					<h1>Summer Sale Stylish</h1>
					<span>Limited Time Offer - Up to 60% off & Free Shipping</span>
					<div className="heroLink">
						<Link to="/shop" onClick={scrollToTop}>
							<h5>Discover More</h5>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default HeroSection;
