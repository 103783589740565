import React, { useState } from "react";
import "./Trendy.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../Features/Cart/cartSlice";
import { Link } from "react-router-dom";
import StoreData from "../../../Data/StoreData";
import { FiHeart } from "react-icons/fi";
import { FaStar, FaCartPlus } from "react-icons/fa";
import toast from "react-hot-toast";
import useProducts from "../../../Hooks/useProducts";

const Trendy = () => {
	const { data: products } = useProducts();
	const dispatch = useDispatch();
	const [wishList, setWishList] = useState({});

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const handleWishlistClick = (product_id) => {
		setWishList((prevWishlist) => ({
			...prevWishlist,
			[product_id]: !prevWishlist[product_id],
		}));
	};

	const cartItems = useSelector((state) => state.cart.items);

	const handleAddToCart = (product) => {
		const productInCart = cartItems.find(
			(item) => item.product_id === product.product_id
		);

		if (productInCart && productInCart.quantity >= 20) {
			toast.error("Product limit reached");
		} else {
			dispatch(addToCart(product));
			toast.success(`Added to cart!`);
		}
	};

	return (
		<>
			<div className="trendyProducts">
				<h2>
					Our Trendy <span>Products</span>
				</h2>
				<div className="trendyTabs">
					<div className="trendyTabContent">
						<div className="trendyMainContainer">
							{products?.results?.slice(0, 4)?.map((product) => (
								<div
									className="trendyProductContainer"
									key={product?.product_id}
								>
									<div className="trendyProductImages">
										<Link to="/Product" onClick={scrollToTop}>
											<img
												src={product?.images[0]?.image_url}
												alt=""
												className="trendyProduct_front"
											/>
											<img
												src={product?.images[1]?.image_url}
												alt=""
												className="trendyProduct_back"
											/>
										</Link>
										<h4 onClick={() => handleAddToCart(product)}>
											Add to Cart
										</h4>
									</div>
									<div
										className="trendyProductImagesCart"
										onClick={() => handleAddToCart(product)}
									>
										<FaCartPlus />
									</div>
									<div className="trendyProductInfo">
										<div className="trendyProductCategoryWishlist">
											<p>Dresses</p>
											<FiHeart
												onClick={() => handleWishlistClick(product.product_id)}
												style={{
													color: wishList[product.product_id]
														? "red"
														: "#767676",
													cursor: "pointer",
												}}
											/>
										</div>
										<div className="trendyProductNameInfo">
											<Link to="product" onClick={scrollToTop}>
												<h5>{product?.name}</h5>
											</Link>

											<p>₹ {product?.discount_price}</p>
											<div className="trendyProductRatingReviews">
												<div className="trendyProductRatingStar">
													<FaStar color="#FEC78A" size={10} />
													<FaStar color="#FEC78A" size={10} />
													<FaStar color="#FEC78A" size={10} />
													<FaStar color="#FEC78A" size={10} />
													<FaStar color="#FEC78A" size={10} />
												</div>
												{/* <span>{product.productReviews}</span> */}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="discoverMore">
					<Link to="/shop" onClick={scrollToTop}>
						<p>Discover More</p>
					</Link>
				</div>
			</div>
		</>
	);
};

export default Trendy;
