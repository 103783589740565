import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../Features/Cart/cartSlice";

import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";
import { FaStar } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { PiShareNetworkLight } from "react-icons/pi";

import { Link, useParams } from "react-router-dom";

import toast from "react-hot-toast";

import "./Product.css";
import useProductDetails from "../../../Hooks/useProductDetails";

const Product = () => {
	// Product images Gallery
	const { product_id } = useParams();
	const { data: product, isLoading } = useProductDetails(product_id);
	const [currentImg, setCurrentImg] = useState(0);

	const prevImg = () => {
		setCurrentImg(
			currentImg === 0 ? product?.images?.length - 1 : currentImg - 1
		);
	};

	const nextImg = () => {
		setCurrentImg(
			currentImg === product?.images.length - 1 ? 0 : currentImg + 1
		);
	};

	// Product Quantity

	const [quantity, setQuantity] = useState(1);

	const increment = () => {
		setQuantity(quantity + 1);
	};

	const decrement = () => {
		if (quantity > 1) {
			setQuantity(quantity - 1);
		}
	};

	const handleInputChange = (event) => {
		const value = parseInt(event.target.value);
		if (!isNaN(value) && value > 0) {
			setQuantity(value);
		}
	};

	// Product WishList

	const [clicked, setClicked] = useState(false);

	const handleWishClick = () => {
		setClicked(!clicked);
	};

	// Product Sizes

	const sizes = ["XS", "S", "M", "L", "XL"];
	const sizesFullName = [
		"Extra Small",
		"Small",
		"Medium",
		"Large",
		"Extra Large",
	];
	const [selectSize, setSelectSize] = useState("S");

	// Product Colors

	const [highlightedColor, setHighlightedColor] = useState("#C8393D");
	const colors = ["#222222", "#C8393D", "#E4E4E4"];
	const colorsName = ["Black", "Red", "Grey"];

	// Product Detail to Redux

	const dispatch = useDispatch();

	const cartItems = useSelector((state) => state.cart.items);

	const handleAddToCart = (product) => {
		// const productInCart = cartItems.find(
		// 	(item) => item.product_id === product?.product_id
		// );

		// if (productInCart && productInCart.quantity >= 20) {
		// 	toast.error("Product limit reached", {
		// 		duration: 2000,
		// 		style: {
		// 			backgroundColor: "#ff4b4b",
		// 			color: "white",
		// 		},
		// 		iconTheme: {
		// 			primary: "#fff",
		// 			secondary: "#ff4b4b",
		// 		},
		// 	});
		// } else {
		dispatch(addToCart(product));
		toast.success(`Added to cart!`);
		// }
	};

	return (
		<>
			<div className="productSection">
				<div className="productShowCase">
					<div className="productGallery">
						<div className="productThumb">
							{product?.images?.map((image, index) => (
								<img
									key={index}
									src={image?.image_url}
									onClick={() => setCurrentImg(index)}
									alt=""
								/>
							))}
						</div>
						<div className="productFullImg">
							<img src={product?.images[currentImg]?.image_url} alt="" />
							<div className="buttonsGroup">
								<button onClick={prevImg} className="directionBtn">
									<GoChevronLeft size={18} />
								</button>
								<button onClick={nextImg} className="directionBtn">
									<GoChevronRight size={18} />
								</button>
							</div>
						</div>
					</div>
					<div className="productDetails">
						<div className="productBreadcrumb">
							<div className="breadcrumbLink">
								<Link to="/">Home</Link>&nbsp;/&nbsp;
								<Link to="/shop">Shop</Link>
							</div>
							<div className="prevNextLink">
								<Link to="/product">
									<GoChevronLeft />
									<p>Prev</p>
								</Link>
								<Link to="/product">
									<p>Next</p>
									<GoChevronRight />
								</Link>
							</div>
						</div>
						<div className="productName">
							<h1>{product?.name}</h1>
						</div>
						<div className="productRating">
							<FaStar color="#FEC78A" size={10} />
							<FaStar color="#FEC78A" size={10} />
							<FaStar color="#FEC78A" size={10} />
							<FaStar color="#FEC78A" size={10} />
							<FaStar color="#FEC78A" size={10} />
							<p>8k+ reviews</p>
						</div>
						<div className="productPrice flex flex-row items-center">
							<h4 className="line-through text-neutral-500">
								₹ {product?.price}
							</h4>
							<h3 className="!m-0 !ml-2 text-neutral-800">
								₹ {product?.discount_price}
							</h3>
						</div>
						<div className="productDescription">
							<p>{product?.short_description}</p>
						</div>
						<div className="productSizeColor">
							<div className="productSize">
								<p>Sizes</p>
								<div className="sizeBtn">
									{sizes.map((size, index) => (
										<Tooltip
											key={size}
											title={sizesFullName[index]}
											placement="top"
											TransitionComponent={Zoom}
											enterTouchDelay={0}
											arrow
										>
											<button
												style={{
													borderColor: selectSize === size ? "#000" : "#e0e0e0",
												}}
												onClick={() => setSelectSize(size)}
											>
												{size}
											</button>
										</Tooltip>
									))}
								</div>
							</div>
							<div className="productColor">
								<p>Color</p>
								<div className="colorBtn">
									{colors.map((color, index) => (
										<Tooltip
											key={color}
											title={colorsName[index]}
											placement="top"
											enterTouchDelay={0}
											TransitionComponent={Zoom}
											arrow
										>
											<button
												className={
													highlightedColor === color ? "highlighted" : ""
												}
												style={{
													backgroundColor: color.toLowerCase(),
													border:
														highlightedColor === color
															? "0px solid #000"
															: "0px solid white",
													padding: "8px",
													margin: "5px",
													cursor: "pointer",
												}}
												onClick={() => setHighlightedColor(color)}
											/>
										</Tooltip>
									))}
								</div>
							</div>
						</div>
						<div className="productCartQuantity">
							<div className="productQuantity">
								<button onClick={decrement}>-</button>
								<input
									type="text"
									value={quantity}
									onChange={handleInputChange}
								/>
								<button onClick={increment}>+</button>
							</div>
							<div className="productCartBtn">
								<button onClick={() => handleAddToCart(product)}>
									Add to Cart
								</button>
							</div>
						</div>
						<div className="productWishShare">
							<div className="productWishList">
								<button onClick={handleWishClick}>
									<FiHeart color={clicked ? "red" : ""} size={17} />
									<p>Add to Wishlist</p>
								</button>
							</div>
							<div className="productShare">
								<PiShareNetworkLight size={22} />
								<p>Share</p>
							</div>
						</div>
						<div className="productTags">
							<p>
								<span>SKU: </span>N/A
							</p>
							<p>
								<span>CATEGORIES: </span>Casual & Urban Wear, Jackets, Men
							</p>
							<p>
								<span>TAGS: </span>biker, black, bomber, leather
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Product;
