import React from "react";
import TermsandConditions from "../Components/Terms/TermsandConditions";

const TermsConditions = () => {
  return (
    <>
      <TermsandConditions />
    </>
  );
};

export default TermsConditions;
