import { useQuery } from "@tanstack/react-query";
import axios from "../Lib/Axios";

const useCategories = () =>
	useQuery({
		queryKey: ["categories"],
		queryFn: () => axios.get("/categories/").then((res) => res.data),
	});

export default useCategories;
