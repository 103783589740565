import React from "react";
import Sidebar from "../Components/Dashboard/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const Account = () => {
	return (
		<>
			<div className="flex flex-row">
				<div className="w-[25%] h-full">
					<Sidebar />
				</div>
				<div className="w-full h-full overflow-y-auto">
					<Outlet />
				</div>
			</div>
			;
		</>
	);
};

export default Account;
